import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const ArticleBody = loadable(() => import("@organisms/ArticleBody"));

const BlogSinglePage = ({ data }) => {
  const { uid, meta, hero, blocks } = data;
  return (
    <PageContainer meta={meta} path={uid}>
      <ArticleBody hero={hero} blocks={blocks} url={meta.url} />
    </PageContainer>
  );
};

export default BlogSinglePage;

/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveContentBuilder from "@dataResolvers/resolveContentBuilder";
import PageTemplate from "@pageTemplates/BlogSinglePage";
// eslint-disable-next-line no-unused-vars
import ContentBuilderFragment from "@fragments/ContentBuilderFragment";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ArticlesQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(uri: $uri, siteId: $siteId) {
        id
        siteId
        type: typeHandle
        title
        uid
        url
        slug
        ... on Craft_articles_article_Entry {
          # Overview
          heroImage: image0 {
            ...ImageFragment
          }
          heroDescriptor: descriptor0
          # Content
          contentBuilder {
            ...ContentBuilderFragment
          }
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              id
              title
            }
          }
          topics {
            id
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    type,
    heroImage,
    authors,
    topics,
    contentBuilder,
    // Meta
    url,
    title,
    date,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  return {
    type,
    hero: {
      heading: title,
      image: resolveImage(heroImage || metaImage),
      topics,
      authors,
      date,
    },
    blocks: resolveContentBuilder(contentBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  const eagerUrls = [];
  return <HeadTemplate {...rest} data={resolvedData} eagerUrls={eagerUrls} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ArticlesQuery"
    {...props}
  />
);

export default Page;
